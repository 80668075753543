import React, { useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Hamburger from 'hamburger-react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default function Popup({
  popUpIsOpen,
  closePopup,
  openPopup,
  children,
  isDark,
}) {
  // add misclick handler to close popup using useRef

  const popupRef = useRef();

  useEffect(() => {
    if (popUpIsOpen) {
      disableBodyScroll(document.body);
    } else {
      enableBodyScroll(document.body);
    }
  }, [popUpIsOpen]);

  const handleClickOutside = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      closePopup();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Detect press of esc button
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') closePopup();
    });

    return () => {
      window.removeEventListener('keydown', closePopup);
    };
  }, []);

  return (
    <AnimatePresence>
      {popUpIsOpen && (
        <div
          className={`fixed inset-0 flex items-center justify-center py-6 backdrop-blur-lg ${isDark ? 'bg-smoky-black/60' : 'bg-white/60'} `}
          style={{ zIndex: 999 }}
        >
          <motion.div
            ref={popupRef}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`relative flex h-full w-full items-center justify-center rounded-t-xl ${isDark ? '' : 'bg-white'} `}
          >
            <div
              className={`${
                isDark ? '' : 'bg-white'
              } absolute right-2 top-2 z-10 rotate-0 rounded-full text-reddish-magenta duration-500 hover:rotate-90 hover:text-purply-blue`}
            >
              <Hamburger
                onToggle={(toggled) => {
                  if (toggled) {
                    openPopup();
                  } else {
                    closePopup();
                  }
                }}
                toggled={popUpIsOpen}
                size={22}
                rounded
              />
            </div>

            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, ease: [0.6, 0.01, -0.05, 0.9] }}
              exit={{ opacity: 0, y: 50 }}
              className="w-full"
            >
              {children}
            </motion.div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}
