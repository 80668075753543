import React, { useState } from 'react';
import Container from '@/components/common/container';
import NewsletterForm from '@/components/common/v4/newsletter-form';
import PreFooter from '@/components/homepage/v4/pre-footer';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Accordion from '../accordion/accordion';

import Popup from '@/components/common/v4/popup';

import { cn } from '@/utils/tailwindUtils';

const FooterLink = ({ link, index }) => {
  if (link._type === 'internalLink') {
    return (
      <Link
        to={`/${link.slug.current}`}
        className="inline-block cursor-pointer py-1 hover:text-primary-600"
      >
        {link.label}
      </Link>
    );
  }
  if (link._type === 'externalLink') {
    return (
      <a
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block cursor-pointer py-1 hover:text-primary-600"
      >
        {link.label}
      </a>
    );
  }
};

const Footer = ({ classes }: { classes?: string }) => {
  const query = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        headerLinks {
          ... on SanityDropDownItem {
            _key
            _type
            dropDownItems {
              ... on SanityExternalLink {
                _key
                _type
                url
                label
              }
              ... on SanityInternalLink {
                _key
                _type
                label
              }
            }
            label
          }
        }

        copyrightMessage
        socialLinks {
          name
          url
        }
        logoLight {
          asset {
            gatsbyImageData
          }
        }
        logoDark {
          asset {
            gatsbyImageData
          }
        }
        faqItems {
          question

          _rawAnswer(resolveReferences: { maxDepth: 10 })
        }
        footerLinks {
          label
          columnItems {
            ... on SanityExternalLink {
              _type
              url
              label
            }
            ... on SanityDownloadLink {
              _type
              url
              label
            }
            ... on SanityInternalLink {
              _type
              label
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);

  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const {
    copyrightMessage,
    faqItems,
    socialLinks,
    headerLinks,
    footerLinks,
    logoLight,
    logoDark,
  } = query.sanitySiteSettings;

  return (
    <>
      <PreFooter />
      <footer
        className={cn(
          'border-t border-black/20 bg-gradient-to-tr from-white via-bluey to-bluey py-20',
          classes,
        )}
      >
        <Container className="lg:px-4">
          <div className="-mx-4 flex flex-wrap items-center justify-center sm:items-start md:justify-between">
            <div className="jusitfy-center flex w-auto flex-col items-center px-4 sm:items-start sm:justify-start">
              <Link
                to="/"
                aria-label="Galileo Home"
                className="cursor-pointer text-smoky-black"
              >
                <img
                  src={logoDark.asset.gatsbyImageData.images.fallback.src}
                  height={40}
                  width={123}
                />
              </Link>

              <div className="w-full md:my-6">
                <p className="-mb-2 mt-8 text-center text-sm text-smoky-black/60 sm:text-left lg:mt-0">
                  Subscribe to our newsletter
                </p>

                <div className="flex flex-row space-x-2">
                  <NewsletterForm
                    cssClass={'inline-newsletter-form'}
                    portalId={'23114811'}
                    formId={'646b31c4-03b9-48bc-bcc6-048771d5caf9'}
                    redirectUrl="/ty/hallucinationindex"
                  />
                </div>
              </div>

              {/* <Fade delay={0.7} duration={0.5}>
              <p className="sm:my-5">
                <button
                  type="button"
                  onClick={openPopup}
                  className="duration-200 group inline-block px-3.5 sm:px-4 py-2.5 sm:py-3.5 text-smoky-black bg-primary-600/20 rounded-full hover:opacity-80 font-semibold leading-none border-2 border-primary-600/60 justify-start items-center"
                >
                  <div className="flex flex-row items-center">
                    <div className="w-4">
                      <FaqIcon />
                    </div>
                    <div className="w-auto pl-4 text-lg">Read our FAQs</div>
                  </div>
                </button>
              </p>
            </Fade> */}

              {/* <p className='text-sm sm:my-5 w-full text-gray-400'>Contact us at <a href="mailto:info@rungalileo.io" className='text-transparent bg-clip-text bg-gradient-to-br from-reddish-magenta via-pb to-purply-blue antialiased'>info@rungalileo.io</a></p> */}
              {/* <p className="text-sm text-gray-400 sm:block hidden mt-4">{copyrightMessage}</p> */}
            </div>
            <div className="sm:order-auto order-last w-full px-4 pt-8 sm:w-auto sm:pt-2">
              <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 xl:ml-6">
                {footerLinks.map((col, idx) => {
                  return (
                    <div className="w-full sm:w-auto md:min-w-[144px]">
                      <p className="mb-4 hidden text-sm font-light uppercase text-grayy sm:block">
                        {col.label}
                      </p>
                      <ul className="flex flex-wrap justify-center space-x-4 text-sm text-smoky-black sm:block sm:flex-nowrap sm:space-x-0 sm:space-y-4">
                        {col.columnItems.map((i, idx) => {
                          return (
                            <li className="my-2">
                              <FooterLink link={i} index={idx} />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="-mx-4 flex flex-wrap justify-center pt-12 sm:justify-between">
            <div className="w-auto px-4">
              <p className="my-4 w-full text-sm text-gray-400">
                Contact us at{' '}
                <a
                  href="mailto:info@rungalileo.io"
                  className="bg-gradient-to-br from-reddish-magenta via-pb to-purply-blue bg-clip-text text-transparent antialiased"
                >
                  info@rungalileo.io
                </a>
              </p>
            </div>
            <div className="w-auto px-4 text-center">
              <p className="my-4 text-sm text-gray-400">{copyrightMessage}</p>
            </div>
          </div>
        </Container>
        <Popup
          popUpIsOpen={isPopupOpen}
          closePopup={closePopup}
          openPopup={openPopup}
          isDark={false}
        >
          <div className="container mx-auto overflow-y-scroll px-6 md:max-w-3xl lg:max-w-4xl">
            <p className="mb-8 text-[36px] leading-none lg:text-[48px]">
              <span className="bg-gradient-to-br from-rm via-purpley to-pb bg-clip-text font-medium text-transparent antialiased">
                FAQ
              </span>
            </p>
            <Accordion data={faqItems} />
          </div>
        </Popup>
      </footer>
    </>
  );
};

export default Footer;
