import React from 'react';
import AccordionItem from './accordion-item';

const Accordion = ({ data }) => {
  return (
    <div className="">
      {data.map((item, idx) => (
        <AccordionItem title={item.question} content={item._rawAnswer} />
      ))}
    </div>
  );
};

export default Accordion;
