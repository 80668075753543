import React, { useState, lazy } from 'react';

import Layout from '@/components/common/v4/layout';
import { graphql } from 'gatsby';

import Hero from '@/components/homepage/v4/hero';
import { Logos } from '@/components/homepage/v4/logos';
import AlgorithmPowered from '@/components/homepage/v4/algorithm-powered';
import DesignedFor from '@/components/homepage/v4/designed-for';
import BuiltFor from '@/components/homepage/v4/built-for';
import FormSection from '@/components/homepage/v4/form';

import Meet from '@/components/homepage/v4/meet';

import Testimonials from '@/components/homepage/v4/testimonials';

import SEO from '@/components/seo';

const sectionTitlePortableText = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <span className="bg-gradient-to-br from-y via-rm to-pb bg-clip-text text-transparent antialiased">
        {children}
      </span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const whySectionTitlePortableText = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <span className="bg-gradient-to-br from-pb via-pb to-pb bg-clip-text text-transparent antialiased">
        {children}
      </span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const Index = ({ data }) => {
  const [isOpen, setOpen] = useState(false);

  const closePopup = () => {
    document.body.style.overflow = 'scroll';
    setOpen(false);
  };
  const openPopup = () => {
    document.body.style.overflow = 'hidden';
    setOpen(true);
  };

  const {
    homepageHeroSection,
    seo,
    testimonialsSection,
    homepageMeetSection,
    homepageAlgorithmPoweredSection,
    homepageDesignedForSection,
    homepageBuiltForSection,
    homepageFormSection,
  } = data.sanityHomePage;

  return (
    <Layout>
      <div className="overflow-hidden bg-[url(/hero-gradient.webp)] bg-[length:150%] bg-[bottom_center] bg-no-repeat md:bg-[length:100%] lg:bg-contain">
        <Hero hero={homepageHeroSection} />

        <div className="border-b border-t border-solid border-y-[#2A2A2A] bg-[rgba(7,7,7,0.80)] py-[31px]">
          <div className="text-center text-base font-bold font-light uppercase">
            {/* <p>{homepageHeroSection.customersTitle}</p> */}
            <Logos logos={homepageHeroSection.customers} />
          </div>
        </div>
      </div>

      <Meet data={homepageMeetSection} />
      <AlgorithmPowered data={homepageAlgorithmPoweredSection} />
      <DesignedFor data={homepageDesignedForSection} />
      <BuiltFor data={homepageBuiltForSection} />

      <Testimonials
        testimonials={testimonialsSection.testimonials}
        title={testimonialsSection.title}
        subtitle={testimonialsSection.subtitle}
      />

      <FormSection data={homepageFormSection} />
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query HomePageQuery {
    cv: file(relativePath: { eq: "computer-vision.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          width: 400
          height: 276
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    genai: file(relativePath: { eq: "gen-ai.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          width: 400
          height: 276
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    nlp: file(relativePath: { eq: "nlp.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          width: 400
          height: 276
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    sanityHomePage {
      seo {
        metaTitle
        metaDescription
        image {
          asset {
            gatsbyImageData(width: 1280)
            url
            localFile(width: 1280) {
              publicURL
              childImageSharp {
                fixed(width: 1280) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }

      homepageHeroSection {
        _rawTitleV2(resolveReferences: { maxDepth: 10 })
        _rawSubTitleV2(resolveReferences: { maxDepth: 10 })

        heroVideo {
          asset {
            url
          }
        }
        customers {
          asset {
            url
          }
        }
        ctaLinksV2 {
          ... on SanityExternalLink {
            _type
            label
            url
          }
          ... on SanityInternalLink {
            isCta
            label
            slug {
              current
            }
            _type
          }
        }
      }
      homepageMeetSection {
        _rawTitle(resolveReferences: { maxDepth: 10 })
        meetSections {
          links {
            ... on SanityExternalLink {
              _type
              label
              url
            }
            ... on SanityInternalLink {
              isCta
              label
              slug {
                current
              }
              _type
            }
          }
          icon {
            asset {
              url
            }
          }
          listItems
          subTitle
          text
          title
          animation {
            asset {
              url
            }
          }
        }
      }

      homepageAlgorithmPoweredSection {
        _rawTitle(resolveReferences: { maxDepth: 10 })
        subTitle

        links {
          ... on SanityExternalLink {
            _key
            _type
            label
            url
          }
          ... on SanityInternalLink {
            _key
            _type
            isCta
            label
            slug {
              current
            }
          }
        }

        ctalinks {
          ... on SanityExternalLink {
            _key
            _type
            label
            url
          }
          ... on SanityInternalLink {
            _key
            _type
            isCta
            label
            slug {
              current
            }
          }
        }
      }

      homepageDesignedForSection {
        _rawTitle(resolveReferences: { maxDepth: 10 })
        ctaSubTitle
        ctaTitle

        subTitle
        links {
          ... on SanityExternalLink {
            _key
            _type
            label
            url
          }
          ... on SanityInternalLink {
            _key
            _type
            isCta
            label
            slug {
              current
            }
          }
        }
      }

      homepageBuiltForSection {
        _rawTitle(resolveReferences: { maxDepth: 10 })
        subTitle
        builtForItems {
          description
          image {
            asset {
              url
            }
          }
          links {
            ... on SanityExternalLink {
              _key
              _type
              label
              url
            }
            ... on SanityInternalLink {
              _key
              _type
              isCta
              label
              slug {
                current
              }
            }
          }
          title
        }
      }

      homepageFormSection {
        _rawTitle(resolveReferences: { maxDepth: 10 })
        features {
          title
          description
          asset {
            asset {
              url
            }
          }
        }
      }

      homepagePreFooterSection {
        subTitle
        title
        builtForItems {
          description
          title
          image {
            asset {
              url
            }
          }
          links {
            ... on SanityExternalLink {
              _key
              _type
              label
              url
            }
            ... on SanityInternalLink {
              _key
              _type
              isCta
              label
              slug {
                current
              }
            }
          }
        }
      }

      testimonialsSection {
        subtitle
        title
        testimonials {
          title
          review
          name
          image {
            asset {
              gatsbyImageData
            }
          }
          logo {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const {
    seo: { metaTitle, metaDescription, image },
  } = data.sanityHomePage;

  return (
    <SEO
      title={metaTitle}
      description={metaDescription}
      image={image ? image.asset.localFile.publicURL : null}
      imageWidth={
        image ? image.asset.localFile.childImageSharp.fixed.width : null
      }
      imageHeight={
        image ? image.asset.localFile.childImageSharp.fixed.height : null
      }
    />
  );
};
