/* eslint-disable react/jsx-fragments */
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Header from '@/components/common/v4/header';
import Footer from '@/components/common/v4/footer';
import { responsiveMediaStyles } from '@/utils/responsive-media';
import { useStaticQuery, graphql } from 'gatsby';
import InfoBar from '@/components/info-bar';
import { useStore } from '@/store';
import { useReplaceLinks } from '@/hooks/useReplaceLinks';

type layoutProps = {
  children?: React.ReactNode;
  title?: string;
  description?: string;
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
};

const Layout = ({ children }: layoutProps) => {
  useReplaceLinks();
  const { closeInfoBar } = useStore();
  const query = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        description
        siteName
        siteUrl
        infoBar {
          infoBarCtaText
          infoBarCtaUrl
          showInfoBar
          countDown
          _rawText(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  `);

  const data = query.sanitySiteSettings;
  const { showInfoBar } = data.infoBar;

  const isInfoBarVisible = !closeInfoBar && showInfoBar;

  return (
    <Fragment>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <style>{responsiveMediaStyles}</style>
        <script
          async
          src="https://tag.clearbitscripts.com/v1/pk_9b17e3cbc6a1dfd3c88f42273c421a6f/tags.js"
          referrerPolicy="strict-origin-when-cross-origin"
        ></script>
        <script
          type="text/javascript"
          async
          src="https://play.vidyard.com/embed/v4.js"
        ></script>
      </Helmet>
      <div className="absolute inset-0 z-0 h-full w-full">
        <div className="container mx-auto h-full w-full max-w-6xl overflow-hidden">
          <div className="bg-[url(/eventsgraphic.svg)]- h-full w-full transform bg-[length:150%] bg-[bottom_center] bg-top bg-no-repeat md:bg-[length:100%] lg:bg-contain"></div>
        </div>
      </div>
      <div
        className={`flex min-h-full flex-col bg-smoky-black ${
          isInfoBarVisible ? 'pt-[5.5rem] lg:pt-[7.75rem]' : 'pt-5 lg:pt-[80px]'
        }`}
      >
        {isInfoBarVisible && <InfoBar infoBar={data.infoBar} />}

        <Header showInfoBar={isInfoBarVisible} />
        <div className="grow">{children}</div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Layout;
