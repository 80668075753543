import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import Container from '@/components/common/container';

const PreFooter = () => {
  const query = useStaticQuery(graphql`
    query PrefooterQuery {
      sanityHomePage {
        homepagePreFooterSection {
          subTitle
          title
          builtForItems {
            description
            title
            image {
              asset {
                url
              }
            }
            links {
              ... on SanityExternalLink {
                _key
                _type
                label
                url
              }
              ... on SanityInternalLink {
                _key
                _type
                isCta
                label
                slug {
                  current
                }
              }
            }
          }
        }
      }
    }
  `);

  const data = query.sanityHomePage.homepagePreFooterSection;

  return (
    <div className="bg-bluey py-20">
      <Container className="relative z-10 space-y-6 lg:px-4">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div className="lg:col-span-2">
            <div className="flex h-full flex-col items-start justify-center">
              <p className="mb-4 max-w-xl text-[24px] font-semibold leading-tight lg:text-[32px]">
                {data.title}
              </p>
              <p className="max-w-xl text-lg text-smoky-black/80">
                {data.subTitle}
              </p>
            </div>
          </div>
          <div className="space-y-4 lg:col-span-1">
            {data.builtForItems.map((i, idx) => {
              return (
                <div
                  className="rounded-lg bg-gradient-to-br from-white to-bluey p-2"
                  key={`prefooter-items-${idx}`}
                >
                  <div className="-mx-2 mb-2 flex flex-row items-center">
                    <div className="w-auto px-2">
                      <div className="flex aspect-square h-20 w-20 items-center justify-center">
                        <img src={i.image.asset.url} alt={i.title} />
                      </div>
                    </div>
                    <div className="w-auto px-2">
                      {i.title && (
                        <h3 className="text-base font-bold text-smoky-black lg:text-lg">
                          {i.title}
                        </h3>
                      )}
                      {i.links &&
                        i.links.map((link, idx) => {
                          if (link._type === 'externalLink') {
                            return (
                              <a
                                key={`cta-link-${idx}`}
                                href={link.url}
                                className="border-b border-primary-600 text-sm text-primary-600 hover:border-dashed lg:text-base"
                              >
                                <span className="bg-gradient-to-br from-rm via-purpley to-pb bg-clip-text text-transparent antialiased">
                                  {link.label}
                                </span>
                              </a>
                            );
                          }

                          return (
                            <a
                              href={`/${link.slug.current}`}
                              key={`internal-link-${idx}`}
                              className="border-b border-primary-600 text-sm text-primary-600 hover:border-dashed lg:text-base"
                            >
                              <span className="bg-gradient-to-br from-rm via-purpley to-pb bg-clip-text text-transparent antialiased">
                                {link.label}
                              </span>
                            </a>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PreFooter;
