import React from 'react';

export default function OpenClose({ isActive, color }) {
  return (
    <div className={`${isActive ? '' : color} relative`}>
      <span
        className={`svg-wrap absolute inset-0 flex h-3 w-3 origin-center transform items-center justify-center transition-transform duration-500 ${
          !isActive ? 'rotate-180' : 'rotate-0'
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 17 10"
          fill="none"
          className={`${
            isActive ? 'opacity-100 grayscale' : 'opacity-50 grayscale'
          } w-full`}
        >
          <path
            d="M1 8.75439L8.5 1.24531L16 8.75439"
            stroke="url(#paint0_linear_3939_4802)"
            stroke-width="1.87727"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3939_4802"
              x1="2.16431"
              y1="7.62021"
              x2="4.45051"
              y2="3.7068"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EF43CD" />
              <stop offset="1" stop-color="#695EFF" />
            </linearGradient>
          </defs>
        </svg>
      </span>
    </div>
  );
}
