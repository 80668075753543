import React from 'react';
import Fade from '@/components/common/fade';
import Container from '@/components/common/container';
import AlgorithPowered from '@/assets/al-powered.svg';
import Lottie from 'react-lottie';
import * as algorithmPoweredData from '@/assets/new-home.json';
import { PortableText } from '@portabletext/react';
import { titlePortableText } from '../v4/hero';

const AlgorithmPowered = ({ data }) => {
  return (
    <div className="bg-smoky-black py-20 pt-[120px] md:py-28">
      <Container className="space-y-6 lg:px-4">
        <h2 className="mb-4 text-center text-[36px] font-medium leading-tight text-white lg:text-[44px]">
          <PortableText
            value={data._rawTitle}
            components={titlePortableText as any}
          />
        </h2>
        <p className="mx-auto max-w-[720px] text-center text-lg text-white/80">
          {data.subTitle}
        </p>
        <p className="space-x-4 text-center">
          {data.links &&
            data.links.map((link, idx) => {
              if (link._type === 'externalLink') {
                return (
                  <a
                    key={`cta-link-${idx}`}
                    href={link.url}
                    className="border-b border-purple-300 text-sm text-purple-300 text-purple-300/80 hover:border-dashed lg:text-base"
                  >
                    {link.label}
                  </a>
                );
              }

              return (
                <a
                  href={`/${link.slug.current}`}
                  key={`internal-link-${idx}`}
                  className="border-b border-purple-300 text-sm text-purple-300 text-purple-300/80 hover:border-dashed lg:text-base"
                >
                  {link.label}
                </a>
              );
            })}
        </p>
        {/* <div className="aspect-video rounded-xl opacity-50 shadow bg-gradient-to-tr from-bluey via-smoky-black via-smoky-black via-smoky-black  to-bluey"></div> */}
        <div className="-my-6 hidden sm:block">
          <Lottie
            options={{
              animationData: algorithmPoweredData,
              loop: true,
              autoplay: true,
            }}
          />
        </div>
        <div className="-mx-4 block py-6 sm:hidden">
          <AlgorithPowered />
        </div>
        <div className="-mx-2 mt-4 flex w-full flex-wrap items-center justify-center text-center">
          {data.ctalinks.map((i, idx) => {
            if (i._type === 'externalLink') {
              return (
                <div className="my-2 w-auto px-2" key={`cta-link-${idx}`}>
                  <a
                    href={i.url}
                    className={`relative inline-block w-[186px] rounded-lg border-2 border-primary-600 bg-primary-600 px-6 py-3.5 font-semibold leading-none text-white duration-200 hover:opacity-80`}
                  >
                    {i.label}
                  </a>
                </div>
              );
            }

            return (
              <div className="my-2 w-auto px-2" key={`internal-link-${idx}`}>
                <a
                  href={`/${i.slug.current}`}
                  className={`relative inline-block w-[186px] rounded-lg border-2 border-primary-600 bg-primary-600 px-6 py-3.5 font-semibold leading-none text-white duration-200 hover:opacity-80`}
                >
                  {i.label}
                </a>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default AlgorithmPowered;
