import React, { useState, useEffect } from 'react';
import Container from '@/components/common/container';
import 'keen-slider/keen-slider.min.css';
import Fade from '@/components/common/fade';
import { useKeenSlider } from 'keen-slider/react';
import ProfilePicture from '@/components/common/profile-picture';
import ArrowLeft from '@/assets/arrow-left.svg';
import ArrowRight from '@/assets/arrow-right.svg';
import GradientText from '@/components/common/gradient-text';

import QuoteMark from '@/assets/quote-mark.svg';
import { GatsbyImage } from 'gatsby-plugin-image';

const isEven = (index: number) => index % 2 === 0;
const animation = { duration: 5000, easing: (t: number) => t };

const Testimonials = ({ testimonials, title, subtitle }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderLoaded, setSetLoaded] = useState(false);
  const [sliderRef, sliderInstanceRef] = useKeenSlider<HTMLDivElement>(
    {
      mode: 'snap',
      loop: true,
      slides: { origin: 'center', perView: 1, spacing: 16 },
      breakpoints: {
        '(min-width: 1024px)': {
          slides: { origin: 'auto', perView: 'auto', spacing: 24 },
        },
      },
      created(slider) {
        setSetLoaded(true);
      },
      updated(slider) {
        setSetLoaded(true);
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
    },
    [
      (slider) => {
        let timeout: ReturnType<typeof setTimeout>;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 3000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ],
  );

  // add useffect to rerender slider on window resize

  useEffect(() => {
    window.addEventListener('resize', () => {
      sliderInstanceRef.current?.update();
    });

    return () => {
      window.removeEventListener('resize', () => {
        sliderInstanceRef.current?.update();
      });
    };
  }, [sliderInstanceRef, sliderLoaded]);

  return (
    <div className="">
      <div className="bg-bluey py-20 md:py-28 xl:py-[120px]">
        <div className="">
          <Container className="lg:px-4">
            <div>
              <div className="text-center">
                <h2 className="mb-4 text-3xl font-semibold md:text-4xl lg:text-5xl">
                  {/* <GradientText>{title}</GradientText> */}
                  What{' '}
                  <span className="bg-gradient-to-b from-rm via-purpley to-pb bg-clip-text text-transparent antialiased">
                    people
                  </span>{' '}
                  are saying
                </h2>

                <p className="mx-auto max-w-xl text-center text-lg">
                  Leaders and data scientists love Galileo
                  {/* {subtitle} */}
                </p>
              </div>
            </div>

            {/* <div className="flex items-center justify-between mt-16">
              {sliderLoaded && sliderInstanceRef.current && (
                <div className="flex gap-4">
                  {Array.from(
                    Array(
                      sliderInstanceRef.current.track.details.slides.length,
                    ).keys(),
                  ).map((index) => {
                    return (
                      <button
                        key={index}
                        onClick={() => {
                          sliderInstanceRef.current?.moveToIdx(index);
                        }}
                        className="w-[12px] p-[2px] h-[12px] bg-gradient-to-br via-reddish-magenta to-purply-blue from-orangey-yellow rounded-full"
                      >
                        <div
                          className={`w-full h-full rounded-full  ${
                            currentSlide === index ? '' : 'bg-white'
                          }`}
                        />
                      </button>
                    );
                  })}
                </div>
              )}

              {sliderLoaded && sliderInstanceRef.current && (
                <div className="flex items-center gap-6">
                  <button
                    className="p-2 disabled:opacity-50"
                    disabled={currentSlide === 0}
                    onClick={(e: any) => {
                      e.stopPropagation() || sliderInstanceRef.current.prev();
                    }}
                  >
                    <ArrowLeft />
                  </button>

                  <button
                    className="p-2 disabled:opacity-50"
                    disabled={
                      currentSlide ===
                      sliderInstanceRef.current.track.details.slides.length - 1
                    }
                    onClick={(e: any) => {
                      e.stopPropagation() || sliderInstanceRef.current.next();
                    }}
                  >
                    <ArrowRight />
                  </button>
                </div>
              )}
            </div> */}
          </Container>

          <Container className="max-w-full lg:px-0">
            <div className="">
              <div
                className="keen-slider mt-9 flex w-full items-stretch xl:mt-24"
                ref={sliderRef}
              >
                {testimonials.map(
                  ({ title, review, image, logo, name }, index) => {
                    return (
                      <div
                        key={index}
                        className="keen-slider__slide h-inherit -my-px flex shrink-0 basis-full flex-col items-center space-y-4 rounded-lg bg-gradient-to-br from-white via-white/50 to-bluey p-6 md:basis-1/2 md:items-start md:p-12 lg:basis-[400px] xl:basis-[450px]"
                      >
                        <QuoteMark />

                        <p className="mt-8 text-center text-sm leading-relaxed text-smoky-black md:text-left">
                          {review}
                        </p>
                        <div className="flex flex-row items-center space-x-2">
                          {/* <ProfilePicture
                          size="sm"
                          bgRingDirection={
                            isEven(index) ? 'topRight' : 'bottomRight'
                          }
                          imageData={image.asset.gatsbyImageData}
                        /> */}

                          <div className="h-12 w-12 flex-1">
                            <GatsbyImage
                              imgClassName="object-cover object-center"
                              className="h-12 h-full w-12 w-full rounded-full"
                              image={image.asset.gatsbyImageData}
                              alt={name}
                            />
                          </div>
                          <div className="">
                            <p className="text-base">{name}</p>
                            <p className="font-outfit max-w-sm text-sm font-light">
                              {title}
                            </p>
                            <div className="fix-logo font-outfit max-w-[90px] text-sm font-light">
                              <GatsbyImage
                                imgClassName=""
                                className="mt-2 h-4"
                                image={logo.asset.gatsbyImageData}
                                alt={name}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
