import React, { useEffect, useState } from 'react';

import { PortableText } from '@portabletext/react';
import { titlePortableText } from '../v4/hero';

const FormSection = ({ data }) => {
  const [submitSucess, setSubmitSucess] = useState(false);

  const createForm = () => {
    window.hbspt.forms.create({
      region: 'na1',
      portalId: '23114811',
      formId: '7e7e5ad9-fbaf-4f20-9433-124b9c3de5a9',
      target: '#hubspotForm',
      sfdcCampaignId: '7014x000000mSYyAAM',
    });
  };

  const onHubSubmit = (event) => {
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmitted' &&
      event.data.id === '7e7e5ad9-fbaf-4f20-9433-124b9c3de5a9'
    ) {
      setSubmitSucess(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onHubSubmit);
    if (!window.hbspt) {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        if (window.hbspt) {
          createForm();
        }
      });
    } else {
      createForm();
    }
    return () => {
      window.removeEventListener('message', onHubSubmit);
    };
  }, []);

  return (
    <section
      className="relative bg-smoky-black py-20 md:py-28 xl:py-36"
      id="try-llm-studio"
    >
      <div className="absolute inset-0 z-0 h-full w-full">
        <div className="container mx-auto h-full w-full max-w-6xl overflow-hidden">
          <div className="h-full w-full transform bg-[url(/new-home-logo-bg.svg)] bg-[length:150%] bg-top bg-no-repeat md:bg-[length:100%] lg:bg-contain"></div>
        </div>
      </div>
      <div className="relative z-10 mx-auto h-full max-w-[1440px] px-4 sm:px-10 lg:px-20 lg:px-4">
        <div className="-mx-4 flex flex-wrap-reverse lg:flex-wrap">
          <div className="w-full px-4 pt-10 lg:w-2/5 lg:pt-5">
            <div className="mb-5 border-b border-white/50 pb-5 text-2xl text-white lg:mb-10 lg:max-w-sm lg:pb-10">
              <PortableText
                value={data._rawTitle}
                components={titlePortableText as any}
              />
            </div>

            <ul className="space-y-5 font-light text-dark-80">
              {data.features.map((i, idx) => {
                return (
                  <li
                    className="-mx-2 flex flex-row items-center"
                    key={`feature-${idx}`}
                  >
                    <div className="w-auto px-2">
                      <div className="h-8 w-8 rounded-full p-px">
                        <div className="flex h-full w-full items-center justify-center rounded-full bg-smoky-black">
                          <img src={i.asset.asset.url} alt={i.title}></img>
                        </div>
                      </div>
                    </div>
                    <div className="w-auto px-2 text-white/50">
                      <span>{i.title}</span>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="w-full px-4 lg:w-3/5 lg:pt-0">
            <div className="rounded-xl border border-dark-600 bg-dark-1000">
              <div className="px-6 py-8 text-white lg:px-10 lg:py-8 xl:px-16 xl:py-16">
                {submitSucess ? (
                  <div className="w-auto rounded px-4 py-1 text-center">
                    👏{' '}
                    <span className="bg-gradient-to-br from-rm via-rm to-purpley bg-clip-text text-transparent antialiased">
                      Thank you!
                    </span>{' '}
                    <p>A member of our team will be in touch with you soon.</p>
                  </div>
                ) : (
                  <div id="hubspotForm" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FormSection;
